<template>
  <div class="row">
       <div class="col-lg-4 col-md-6 col-12 item">
          <!-- Begin services item -->
          <router-link to="#" class="services-item item-style">
            <div class="services-item-ico">
              <i class="material-icons material-icons-outlined md-48">code</i>
            </div>
            <h4 class="services-item-title">Vue.js</h4>
            <div
              class="services-item-desc"
            >There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.</div>
          </router-link>
          <!-- End services item -->
        </div>

        <div class="col-lg-4 col-md-6 col-12 item">
          <!-- Begin services item -->
          <router-link to="#" class="services-item item-style">
            <div class="services-item-ico">
              <i class="material-icons material-icons-outlined md-48">code</i>
            </div>
            <h4 class="services-item-title">Umbraco</h4>
            <div
              class="services-item-desc"
            >Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</div>
          </router-link>
          <!-- End services item -->
        </div>

        <div class="col-lg-4 col-md-6 col-12 item">
          <!-- Begin services item -->
          <router-link to="#" class="services-item item-style">
            <div class="services-item-ico">
              <i class="material-icons material-icons-outlined md-48">code</i>
            </div>
            <h4 class="services-item-title">xxx</h4>
            <div
              class="services-item-desc"
            >ontrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it.</div>
          </router-link>
          <!-- End services item -->
        </div>
  </div>
</template>
<script>
    export default {};
</script>

<style>
</style>